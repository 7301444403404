import React, { SVGProps } from 'react';

const SvgPhoneRingSolidIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7.28571C3 6.02335 4.02335 5 5.28571 5H6.33076C6.98629 5 7.55769 5.44614 7.71668 6.08209L8.55919 9.45214C8.6986 10.0098 8.49024 10.5966 8.03042 10.9415L7.0448 11.6807C6.94245 11.7574 6.9202 11.8699 6.94895 11.9483C7.8141 14.3101 9.68992 16.1859 12.0517 17.051C12.1302 17.0798 12.2426 17.0575 12.3193 16.9552L13.0585 15.9696C13.4034 15.5098 13.9902 15.3014 14.5479 15.4408L17.9179 16.2833C18.5539 16.4423 19 17.0137 19 17.6692V18.7143C19 19.9767 17.9767 21 16.7143 21H15C8.37258 21 3 15.6274 3 9V7.28571Z"
      fill={props.color || 'currentColor'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7208 11.7902C20.4908 7.06318 16.7174 3.26659 12 3V4.16001C16.0777 4.4237 19.3339 7.70283 19.5614 11.7902H20.7208ZM17.2359 11.7902C17.0177 8.98262 14.7967 6.73689 12 6.48026V7.64431C14.1566 7.89274 15.8623 9.62255 16.0736 11.7902H17.2359Z"
      fill={props.color || 'currentColor'}
    />
  </svg>
);
SvgPhoneRingSolidIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPhoneRingSolidIcon;

import React, { SVGProps } from 'react';

const SvgNewCarLeaseColorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx={20} cy={36} rx={15} ry={1} fill="#737373" />
    <path
      d="M2.15148 22.386C2.18066 22.346 2.22723 22.3223 2.2768 22.3223H3.50697C3.98062 22.3223 4.36458 22.7062 4.36458 23.1799V24.0139C4.36458 24.3879 4.06144 24.691 3.6875 24.691H1.90913C1.76947 24.691 1.65625 24.5778 1.65625 24.4381V23.9072C1.65625 23.3605 1.82964 22.8279 2.15148 22.386Z"
      fill="#737373"
    />
    <path
      d="M33.9333 33.7923C33.9333 35.072 33.1264 35.8507 32.4167 35.8507C31.7069 35.8507 30.9 35.072 30.9 33.7923C30.9 32.5127 31.7069 31.734 32.4167 31.734C33.1264 31.734 33.9333 32.5127 33.9333 33.7923Z"
      fill="#BEBEBE"
      stroke="#121212"
      strokeWidth={1.3}
    />
    <path
      d="M27.4314 33.7923C27.4314 35.072 26.6245 35.8507 25.9147 35.8507C25.2049 35.8507 24.398 35.072 24.398 33.7923C24.398 32.5127 25.2049 31.734 25.9147 31.734C26.6245 31.734 27.4314 32.5127 27.4314 33.7923Z"
      fill="#BEBEBE"
      stroke="#121212"
      strokeWidth={1.3}
    />
    <path
      d="M10.7507 33.25C10.7507 35.0449 9.53809 36.5 8.04232 36.5C6.54655 36.5 5.33398 35.0449 5.33398 33.25C5.33398 31.4551 6.54655 30 8.04232 30C9.53809 30 10.7507 31.4551 10.7507 33.25Z"
      fill="#121212"
    />
    <path
      d="M11.8327 33.25C11.8327 35.0449 10.6201 36.5 9.12435 36.5C7.62858 36.5 6.41602 35.0449 6.41602 33.25C6.41602 31.4551 7.62858 30 9.12435 30C10.6201 30 11.8327 31.4551 11.8327 33.25Z"
      fill="#121212"
    />
    <path
      d="M23.748 30H34.0112L34.2247 30.8112C34.4615 31.7111 34.5814 32.6377 34.5814 33.5682V34.3333H23.748V30Z"
      fill="#121212"
    />
    <path
      d="M15.084 28.917H27.3998L27.754 30.3191C27.9731 31.1866 28.084 32.0779 28.084 32.9726V34.3337H15.084V28.917Z"
      fill="#121212"
    />
    <path
      d="M21.582 28.0497C21.582 27.93 21.679 27.833 21.7987 27.833H37.7833C37.903 27.833 38 27.93 38 28.0497V31.9497C38 32.0693 37.903 32.1663 37.7833 32.1663H21.7987C21.679 32.1663 21.582 32.0693 21.582 31.9497V28.0497Z"
      fill="#121212"
    />
    <path
      d="M17 25.5417C17 25.2425 17.2425 25 17.5417 25H38.4583C38.7575 25 39 25.2425 39 25.5417V26.4583C39 26.7575 38.7575 27 38.4583 27H17.5417C17.2425 27 17 26.7575 17 26.4583V25.5417Z"
      fill="#919191"
    />
    <path
      d="M18.3327 33.25C18.3327 35.0449 17.1201 36.5 15.6243 36.5C14.1286 36.5 12.916 35.0449 12.916 33.25C12.916 31.4551 14.1286 30 15.6243 30C17.1201 30 18.3327 31.4551 18.3327 33.25Z"
      fill="#121212"
    />
    <path
      d="M11.832 26.2087C11.832 25.9095 12.0745 25.667 12.3737 25.667H38.4583C38.7575 25.667 39 25.9095 39 26.2087V30.542C39 30.8411 38.7575 31.0837 38.4583 31.0837H12.3737C12.0745 31.0837 11.832 30.8411 11.832 30.542V26.2087Z"
      fill="#DDE0E4"
    />
    <path
      d="M4.68032 18.6854C5.18302 17.6542 6.22977 17 7.37695 17H20.1116C20.6463 17 21.101 17.3901 21.1823 17.9186L22.0081 23.2858C22.0855 23.789 22.1243 24.2973 22.1243 24.8064V30C22.1243 30.5983 21.6393 31.0833 21.041 31.0833H19.973C18.2291 31.0833 16.5566 31.7761 15.3234 33.0093C15.1693 33.1634 14.9602 33.25 14.7422 33.25H2.62435C2.02604 33.25 1.54102 32.765 1.54102 32.1667V27.125C1.54102 25.809 1.84073 24.5102 2.41742 23.3272L4.68032 18.6854Z"
      fill="#ECEFF4"
    />
    <path
      d="M35.0154 33.7923C35.0154 35.072 34.2085 35.8507 33.4987 35.8507C32.7889 35.8507 31.982 35.072 31.982 33.7923C31.982 32.5127 32.7889 31.734 33.4987 31.734C34.2085 31.734 35.0154 32.5127 35.0154 33.7923Z"
      fill="#BEBEBE"
      stroke="#121212"
      strokeWidth={1.3}
    />
    <path
      d="M29.1673 33.7923C29.1673 35.2881 28.1973 36.5007 27.0007 36.5007C25.804 36.5007 24.834 35.2881 24.834 33.7923C24.834 32.2965 25.804 31.084 27.0007 31.084C28.1973 31.084 29.1673 32.2965 29.1673 33.7923Z"
      fill="#121212"
    />
    <path
      d="M15.084 35.417H16.709V36.392C16.709 36.4518 16.6605 36.5003 16.6007 36.5003H15.6257L15.084 35.417Z"
      fill="#121212"
    />
    <path
      d="M31.875 35.417H33.5V36.392C33.5 36.4518 33.4515 36.5003 33.3917 36.5003H32.4167L31.875 35.417Z"
      fill="#121212"
    />
    <path
      d="M25.375 35.417H27V36.392C27 36.4518 26.9515 36.5003 26.8917 36.5003H25.9167L25.375 35.417Z"
      fill="#121212"
    />
    <path
      d="M8.04102 35.417H9.66602L9.15429 36.4404C9.13594 36.4771 9.09843 36.5003 9.0574 36.5003H8.04102V35.417Z"
      fill="#121212"
    />
    <path
      d="M0.998347 29.9173C0.998181 29.3649 1.44595 28.917 1.99835 28.917L12.916 28.917C13.2152 28.917 13.4577 29.1595 13.4577 29.4587V32.7087C13.4577 33.0078 13.2152 33.2503 12.916 33.2503H1.99905C1.44688 33.2503 0.999215 32.8028 0.999049 32.2506L0.998347 29.9173Z"
      fill="#ECEFF4"
    />
    <path
      d="M18.6564 33.25C18.6564 34.7614 17.6597 35.7417 16.7064 35.7417C15.753 35.7417 14.7564 34.7614 14.7564 33.25C14.7564 31.7386 15.753 30.7583 16.7064 30.7583C17.6597 30.7583 18.6564 31.7386 18.6564 33.25Z"
      fill="#BEBEBE"
      stroke="#121212"
      strokeWidth={1.51667}
    />
    <path
      d="M5.18232 18.3824C5.27407 18.1989 5.46163 18.083 5.6668 18.083H14.6658C14.8772 18.083 15.0324 18.2817 14.9811 18.4868L13.5596 24.1727C13.4993 24.4138 13.2827 24.583 13.0341 24.583H2.60789C2.36629 24.583 2.20916 24.3288 2.3172 24.1127L5.18232 18.3824Z"
      fill="#8A95B6"
    />
    <path
      d="M14.6083 24.3138L15.9609 18.9036C16.0814 18.4213 16.5147 18.083 17.0119 18.083H18.7447C19.1321 18.083 19.4627 18.3631 19.5264 18.7452L19.5473 18.8709C19.8204 20.5095 19.9577 22.1679 19.9577 23.829C19.9577 24.2454 19.6201 24.583 19.2037 24.583H14.8185C14.6776 24.583 14.5741 24.4505 14.6083 24.3138Z"
      fill="#353C51"
    />
    <path
      d="M1.94458 28.9167H1.54167C1.20068 28.2347 1.20068 27.432 1.54167 26.75L2.04968 28.7821C2.06677 28.8504 2.01506 28.9167 1.94458 28.9167Z"
      fill="#3C5FBF"
    />
    <path
      d="M11.2904 28.9173H8.90206C8.74877 28.9173 8.64396 28.7625 8.70089 28.6202L9.61089 26.3452C9.64379 26.2629 9.72346 26.209 9.81206 26.209H11.2904C11.5895 26.209 11.832 26.4515 11.832 26.7507V28.3757C11.832 28.6748 11.5895 28.9173 11.2904 28.9173Z"
      fill="#3C5FBF"
    />
    <path
      d="M2.59877 31.2703C2.61381 31.1633 2.70533 31.0838 2.81333 31.0838H7.86062C7.96543 31.0838 8.05521 31.1588 8.07384 31.262L8.28903 32.4536C8.31303 32.5866 8.21088 32.7088 8.07581 32.7088H2.64575C2.51409 32.7088 2.41286 32.5923 2.4312 32.462L2.51074 31.8963L2.59877 31.2703Z"
      fill="#414141"
    />
    <path d="M2.81641 28.8076H7.50085" stroke="#737373" strokeWidth={0.5} strokeLinecap="round" />
    <path
      d="M19.416 26.4257C19.416 26.306 19.513 26.209 19.6327 26.209H19.8493C19.9092 26.209 19.9577 26.2575 19.9577 26.3173V27.7257C19.9577 27.7855 19.9092 27.834 19.8493 27.834H19.6327C19.513 27.834 19.416 27.737 19.416 27.6173V26.4257Z"
      fill="#737373"
    />
    <path
      d="M15.4506 22.2242C15.4956 22.1341 15.5877 22.0771 15.6885 22.0771H16.9316C17.3509 22.0771 17.6908 22.417 17.6908 22.8363V24.1084C17.6908 24.4823 17.3876 24.7855 17.0137 24.7855H15.3117C15.1299 24.7855 14.9824 24.638 14.9824 24.4562V24.2073C14.9824 23.5189 15.1427 22.8399 15.4506 22.2242Z"
      fill="#737373"
    />
    <path
      d="M14.7875 22.3753C14.7733 22.2565 14.858 22.1486 14.9768 22.1344C15.0957 22.1201 15.2035 22.2049 15.2178 22.3237L15.424 24.0447C15.4383 24.1635 15.3535 24.2714 15.2347 24.2856C15.1159 24.2999 15.008 24.2151 14.9938 24.0963L14.7875 22.3753Z"
      fill="#737373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.97421 18.084H14.667C14.8785 18.084 15.0336 18.2827 14.9823 18.4878L13.5609 24.1737C13.5006 24.4148 13.2839 24.584 13.0354 24.584H2.60914C2.36754 24.584 2.21041 24.3297 2.31845 24.1136L2.82508 23.1004L8.97421 18.084Z"
      fill="#353C51"
    />
    <rect x={19.4} y={7.4} width={19.0297} height={9.47539} rx={4.7377} fill="white" />
    <rect x={19.4} y={7.4} width={19.0297} height={9.47539} rx={4.7377} stroke="#1E42A6" strokeWidth={0.8} />
    <path
      d="M31.2891 10.9414C31.2891 11.2422 31.3381 11.5418 31.436 11.8403C31.534 12.1388 31.689 12.4146 31.9009 12.6675C32.1151 12.9204 32.3851 13.1243 32.7109 13.2793L32.1709 14.0176C31.8542 13.8763 31.5807 13.6758 31.3506 13.416C31.1227 13.1562 30.9416 12.8532 30.8071 12.5068C30.6681 12.8805 30.4779 13.2075 30.2363 13.4878C29.9948 13.7658 29.7031 13.9766 29.3613 14.1201L28.8281 13.3613C29.1631 13.2155 29.4411 13.0116 29.6621 12.7495C29.8831 12.4875 30.0461 12.2004 30.1509 11.8882C30.2557 11.5737 30.3092 11.2581 30.3115 10.9414V10.7842H29.0605V10.0049H30.3047V9.15039H31.2959V10.0049H32.5264V10.7842H31.2891V10.9414ZM33.9414 11.3926H34.8301V12.2129H33.9414V15.2754H32.957V9H33.9414V11.3926Z"
      fill="#1E42A6"
    />
    <path
      d="M28.3252 13.5801H27.3408V9.00684H28.3252V13.5801ZM28.4824 15.1729H24.0732V13.1289H25.0713V14.3867H28.4824V15.1729ZM25.5156 9.9502C25.5111 10.3854 25.6239 10.7751 25.854 11.1191C26.0841 11.4609 26.4499 11.7093 26.9512 11.8643L26.4521 12.6367C26.1104 12.5273 25.8198 12.369 25.5806 12.1616C25.3413 11.952 25.1499 11.7025 25.0063 11.4131C24.8605 11.7275 24.6634 11.9987 24.415 12.2266C24.1667 12.4544 23.8659 12.6253 23.5127 12.7393L23 11.9668C23.3486 11.8529 23.6335 11.6911 23.8545 11.4814C24.0778 11.2718 24.2396 11.036 24.3398 10.7739C24.4401 10.5096 24.4902 10.235 24.4902 9.9502V9.34863H25.5156V9.9502Z"
      fill="#1E42A6"
    />
  </svg>
);
SvgNewCarLeaseColorIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgNewCarLeaseColorIcon;

import React, { SVGProps } from 'react';

const SvgPhoneRingOutlineIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 7.14286C4 5.95939 4.95939 5 6.14286 5H7.12259C7.73715 5 8.27284 5.41826 8.42189 6.01446L9.21174 9.17388C9.34243 9.69664 9.1471 10.2468 8.71602 10.5701L7.792 11.2631C7.69605 11.3351 7.67519 11.4405 7.70214 11.5141C8.51322 13.7282 10.2718 15.4868 12.4859 16.2979C12.5595 16.3248 12.6649 16.3039 12.7369 16.208L13.4299 15.284C13.7532 14.8529 14.3034 14.6576 14.8261 14.7883L17.9855 15.5781C18.5817 15.7272 19 16.2629 19 16.8774V17.8571C19 19.0406 18.0406 20 16.8571 20H15.25C9.0368 20 4 14.9632 4 8.75V7.14286Z"
      stroke={props.color || 'currentColor'}
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7208 11.7902C20.4908 7.06318 16.7174 3.26659 12 3V4.16001C16.0777 4.4237 19.3339 7.70283 19.5614 11.7902H20.7208ZM17.2359 11.7902C17.0177 8.98262 14.7967 6.73689 12 6.48026V7.64431C14.1566 7.89274 15.8623 9.62255 16.0736 11.7902H17.2359Z"
      fill={props.color || 'currentColor'}
    />
  </svg>
);
SvgPhoneRingOutlineIcon.defaultProps = {
  width: '24',
  height: '24',
  color: 'currentColor',
};
export default SvgPhoneRingOutlineIcon;
